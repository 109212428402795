<template>
  <div class="home pt-4">
    <b-container>
      <b-row>
        <MainLeftBlock :dataSport="dataSport"></MainLeftBlock>
        <MainCenterBlock :dataSport="dataSport"></MainCenterBlock>
        <MainRightBlock :dataSport="dataSport"></MainRightBlock>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import MainLeftBlock from '@/components/blocks/MainLeftBlock.vue';
import MainCenterBlock from '@/components/blocks/MainCenterBlock.vue';
import MainRightBlock from '@/components/blocks/MainRightBlock.vue';

export default {
  name: "efootball_home",
  data() {
    return {
      dataSport:
        {
          textClass: "fifa-text-color",
          kindOfSport: "efootball",
          sportId: 2,
          link_api: "fifa"
        }
    }
  },
  components: {
    MainLeftBlock,
    MainCenterBlock,
    MainRightBlock
  },
  mounted() {

  },
  metaInfo () {
      return {
          title: this.$t('meta.sports.fifa.main.title'),
          titleTemplate: 'H2H Liga | %s',
          meta: [
            {name: 'description', content: this.$t('meta.sports.fifa.main.description')},
            {name: 'keywords', content: this.$t('meta.sports.fifa.main.keywords')}
          ]
      }
  }
};
</script>
<style>
.game-border-bottom.efootball_border:not(:last-child):hover {
  border-bottom: 1px solid rgba(112, 251, 3, 0.58)!important;
  transition: all 0.5s;
}
</style>
